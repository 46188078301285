<script setup lang="ts">
import { EContent } from 'auth/enums/dialog-contents'
import IconUserAccept from '@/assets/images/user-accept.svg'

defineEmits<{
  (e: 'switch', value: EContent): void
  (e: 'close'): void
}>()

const authStore = useAuthStore()
const isConfirmed = ref(false)

const confirmEmail = async () => {
  const router = useRouter()
  const route = useRoute()
  const token = route.query['email-token'] as string

  if (token) {
    isConfirmed.value = await authStore.confirmEmail(token)
  }

  router.push({
    query: {},
  })
}

confirmEmail()
</script>

<template>
  <div
    v-if="!authStore.isProcessing"
    class="flex flex-col items-center"
  >
    <template v-if="isConfirmed">
      <IconUserAccept aria-hidden="true" />
      <h2 class="my-8 mt-6 text-center text-4xl font-normal">
        Account Created
      </h2>
      <div class="flex w-full flex-col items-center">
        <p class="text-center text-base font-normal text-gray-100">
          Your email was successfully verified and your account is ready.
        </p>
        <Button
          type="button"
          label="Log In"
          class="mt-8 w-full"
          @click="$emit('switch', EContent.SIGN_IN)"
        />
      </div>
    </template>
    <template v-else>
      <h2 class="my-8 mt-6 text-center text-4xl font-normal">
        Wrong email token
      </h2>
      <div class="flex w-full flex-col items-center">
        <p class="text-center text-base font-normal text-gray-100">
          Email token is not found. Please try again.
        </p>
        <Button
          type="button"
          label="Done"
          class="mt-8 w-full"
          @click="$emit('close')"
        />
      </div>
    </template>
  </div>
</template>
